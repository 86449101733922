import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
//import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"

const generalData = {
    title: "はじめてつくるNext.jsサイト（v12）",
    updated: "2023/2/15",
    interview1: "",
    urlToApp: "https://next-book-portfolio-site.vercel.app",
    urlToCode1: "https://github.com/mod728/nextjs-book-portfolio-website",
    urlToCode2: "",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B09Z98N6ZL",
    reviewLink2: "",
    codePageUrl: "https://monotein-books.netlify.app/nextbook",
}

const booksData = [
    {newRelease: "", title: "Next.jsでつくるフルスタックアプリ（前編・後編）", img: NextBook2a, url: "https://www.amazon.co.jp/dp/B09YWSHR58", desciption: "「Next.jsをバックエンドとフロントエンド両方に使いたい」<br/><br/>「サーバーサイド・レンダリングをしてみたい」<br/><br/>という人向けの本書続編です。"},
    {newRelease: "", title: "はじめてつくるGatsbyサイト", img: GatsbyBook , url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", desciption: "「Reactはだいたいわかったけど、次に何を勉強すればいいかわからない・・・」<br/><br/>という人向けのGatsby入門書です。"},
    {newRelease: "", title: "はじめてつくるJamstackサイト with ヘッドレスCMS", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", desciption: "「WordPress以外でウェブ制作をしてみたい」<br/><br/>という人向けの入門書。<br/><br/>ヘッドレスCMSと静的サイトジェネレーターを使ったJamstackサイト開発を紹介します。"},
    {newRelease: "", title: "はじめてつくるNuxtサイト", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", desciption: "Next.jsのVue版、Nuxt入門に最適な一冊。本書と同じサイトを作るので、Next.jsとNuxtの違いがよくわかります。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", desciption: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", desciption: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", desciption: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", desciption: ""},
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", desciption: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", desciption: ""},
    {newRelease: "", title: "", img: MernBook, url: "https://www.amazon.co.jp/dp/B0BJN4H1NF", desciption: ""},
    {newRelease: "", title: "", img: NextBook2aTS, url: "https://www.amazon.co.jp/dp/B0BD552RD6", desciption: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", desciption: ""},
    {newRelease: "", title: "", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", desciption: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", desciption: ""},
]

const links = [
    {
        chapterNum: 1,
        data: [
            {name: "Node.js", url: "https://nodejs.org/en/download"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
        ]
    },
    {
        chapterNum: 3,
        data: [
            {name: "画像のダウンロード", url: "https://github.com/mod728/nextjs-book-portfolio-website/tree/chapter3/public/images"},
            {name: "CSSファイル", url: "https://github.com/mod728/nextjs-book-portfolio-website/tree/chapter3/src/styles"},
            {name: "マークダウンファイル", url: "https://github.com/mod728/nextjs-book-portfolio-website/tree/chapter3/src/data"},
            {name: "第3章完了時のソースコード", url: "https://github.com/mod728/nextjs-book-portfolio-website/tree/chapter3"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "Vercel", url: "https://vercel.com"},
        ]
    },
    {
        chapterNum: 5,
        data: [
            {name: "Formspress", url: "https://formspree.io/create/zeit"},
            {name: "第5章完了時のソースコード", url: "https://github.com/mod728/nextjs-book-portfolio-website"},
            {name: "アマゾンレビューURL", url: generalData.reviewLink1},
        ]
    },
]

const NextJsBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            <li><span style={{color: "red"}}>*第5章「pagination」部分のコード訂正について</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/nextjs-beginner-book-pagination-fix">解説ページへ移動する</a></li>
            <li><span style={{color: "red"}}>*Next.js version 13使用時の表示崩れについて</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/code-nextjs-v13-fix">解説ページへ移動する</a></li>
            <br/>
            {generalData.interview1 && <li>本書に関するインタビュー記事：<a rel="noopener noreferrer" target="_blank" href={generalData.interview1}>{generalData.interview1}</a></li>}
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコードダウンロード：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
            {generalData.urlToCode2 && 
                <li>ソースコードダウンロード（後編）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode2}>{generalData.urlToCode2}</a></li>
            }
            {generalData.codePageUrl &&
                <li>本書のコード一覧：<a rel="noopener noreferrer" target="_blank" href={generalData.codePageUrl}>{generalData.codePageUrl}</a></li>
            }
            <li><span className="amazon-review">Amazonでレビューする</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
            {generalData.reviewLink2 && 
                <li><span className="amazon-review">Amazonでレビューする（後編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink2}>{generalData.reviewLink2}</a></li>
            }
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <h3>『{x.title}』</h3>
                            <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            <p dangerouslySetInnerHTML={{ __html: x.desciption }}/>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
    </div>
)

export default NextJsBookLinks
